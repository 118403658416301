<template>
  <div class="new-password">
    <h1>Reset password</h1>

    <Alert v-if="resetPasswordError.status" class="new-password__alert" style-modifiers="warning">
      <span style=" font-weight: bold; color: darkred!important; ">Reset password failed!</span>
      {{ resetPasswordError.msg }}
    </Alert>

    <div class="new-password__form">
      <TextInput
          id="password"
          v-model="password"
          name="password"
          type="password"
          mode="auth"
          placeholder="Password"
          :validate="passwordValidate"
      />

      <TextInput
          id="password-confirm"
          v-model="passwordConfirm"
          name="password-confirm"
          type="password"
          mode="auth"
          placeholder="Confirm password"
          @focusout="passwordsMatch"
      />
    </div>

    <div class="new-password__action">
      <Button
          style-modifiers="success"
          text="Reset password"
          class="new-password__button"
          @click="resetPasswordController"
          :loading="resetPasswordLoad"
      />
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/UI-Kit/TextInput";
import Button from "@/components/UI-Kit/Button";
import { authFormValidateSetup, authSetup } from "@/setups/authSetup";
import { ref, reactive } from "vue";
import Alert from "@/components/UI-Kit/Alert";
import {gsap} from 'gsap';

export default {
  name: "TheAuthNewPasswordForm",
  components: { Button, TextInput, Alert },
  props: {
    code: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {

    const { password, code, resetPassword } = authSetup();
    const { passwordValidate } = authFormValidateSetup();

    code.value = props.code

    const resetPasswordError = reactive({
      status: false,
      msg: ""
    });

    const resetPasswordLoad = ref(false)

    const passwordConfirm = ref('')

    const passwordsMatch = function () {
      if (passwordConfirm.value !== password.value) {
        resetPasswordError.status = true
        resetPasswordError.msg = "Passwords do not match"
        return false
      }
      return true
    }

    const resetPasswordController = async function() {

      if (!passwordsMatch()) return

      resetPasswordLoad.value = true

      try {
        await resetPassword();
        emit('reset-success')
      } catch (err) {
        if (err.name === "ValidationError") {
          gsap.to('.text-input_error', {x:"+=12", duration: 0.3, yoyo:true, repeat:1})
        } else if (err.name === "Error") {
          resetPasswordError.status = true;
          resetPasswordError.msg = err.response.data.message[0].messages[0].message
        }
      }

      resetPasswordLoad.value = false
    };



    return {
      resetPasswordError,
      resetPasswordController,
      passwordValidate,
      passwordConfirm, password,
      passwordsMatch
    };
  }
}
</script>

<style scoped lang="scss">
.new-password {
  padding: 32px 0;
  background-color: white;

  width: 100%;

  * {
    width: 100%;
  }

  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 24px;
    color: #000000;

    opacity: 0.87;
    text-align: left;
  }

  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin-top: 10px;
  }

  &__action {
    margin-top: 40px;
  }

  &__button {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    width: 240px;
    height: 40px;
  }

  &__alert {
    text-align: left;
    font-size: 13px;
    line-height: 150%;
    border-radius: 8px;
    margin-top: 16px;
    margin-bottom: 24px;
  }
}
</style>
