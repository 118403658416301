<template>
  <div class="pxp__new-password-page">
    <Card size="small" class="new-password-page__new-password">
      <template v-slot:header>
        <div class="new-password-page__header">
          <img
              src="@/assets/images/pxp-logo.svg"
              alt="pagexpage logo"
              class="new-password-page__logo"
          />
        </div>
      </template>
      <template v-slot:body>
        <TheAuthNewPasswordForm :code="code" @reset-success="resetSuccessRoute"/>
      </template>
    </Card>
  </div>
</template>

<script>
import TheAuthNewPasswordForm from "@/components/TheAuthNewPasswordForm";
import {useRouter} from 'vue-router';
import {logout} from "@/setups/authSetup";
import Card from "@/components/UI-Kit/Card";

export default {
  name: "NewPassword",
  components: {Card, TheAuthNewPasswordForm},
  props: {
    code: {
      type: String,
      required: true
    }
  },
  setup() {
    const router = useRouter()

    const resetSuccessRoute = function () {
      logout();
      router.push("/auth")
    }

    return {
      resetSuccessRoute
    }
  }
};
</script>

<style scoped lang="scss">

.pxp__new-password-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  place-items: center center;
}

.new-password-page__new-password {
  padding: 32px;
  width: 34%;
  margin: auto;
  box-sizing: border-box;
}

.new-password-page__logo {
  width: 182px;
  height: 32px;
  float: left;
}
</style>
